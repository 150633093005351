<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Deposit Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
            <div class="mt-4 flex justify-between">
              <date-range-picker
                style="min-height: 40px"
                class="w-full"
                ref="picker"
                opens="center"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :autoApply="true"
                v-model="date"
                :linkedCalendars="true"
              >
                <template v-slot:input="picker">
                  {{ globalDateFormat(picker.startDate) }} -
                  {{ globalDateFormat(picker.endDate) }}
                </template>
              </date-range-picker>
              <vs-button
                class="ml-4"
                color="danger"
                icon-pack="feather"
                icon="icon-x"
                @click="
                  () => {
                    this.date = { startDate: null, endDate: null };
                  }
                "
              >
              </vs-button>
              <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                    />
                  </vx-tooltip>
                </template> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-12">
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Operating Unit Bank </span>
            <div class="vx-col sm:w-1/3 w-full"></div>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="operatingUnitBank"
              :options="optionOperatingUnitBank"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :custom-label="customLabel"
              :searchable="true"
              :filterable="true"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">
                    {{ dt.option.BankName }}
                    <br />
                    {{ dt.option.AccountName }}
                    <br />
                    {{ dt.option.AccountNumber }}
                  </span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">
                    {{ dt.option.BankName }}
                    <br />
                    {{ dt.option.AccountName }}
                    <br />
                    {{ dt.option.AccountNumber }}
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Operating Unit</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="operatingUnit"
              :options="optionOU"
              :multiple="true"
              :allow-empty="true"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="ID"
              label="Name"
              @input="selectedOperatingUnit"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >({{ props.option.Code }}), {{ props.option.Name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    <span class="option__title"
                      >({{ props.option.Code }}), {{ props.option.Name }}</span
                    >
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="territory"
              :options="optionTerritory"
              :multiple="true"
              :allow-empty="true"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="ID"
              label="Name"
              @input="selectedTerritory"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >({{ props.option.Code }}), {{ props.option.Name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    <span class="option__title"
                      >({{ props.option.Code }}), {{ props.option.Name }}</span
                    >
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Data Display</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="recommendationFilter"
              :options="recommendationFilterOptions"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="id"
              label="name"
            >
              <!-- <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.name }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    <span class="option__title">{{ props.name }}</span>
                  </span>
                </div>
              </template> -->
            </multiselect>
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button
              class="ml-4 mt-2"
              color="primary"
              icon-pack="feather"
              icon="icon-printer"
              v-on:click="downloadExcel"
              >Download Excel</vs-button
            >
          </div>
        </div>
      </div>

      <div
        v-if="renderContent"
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <vs-tabs v-model="currentTab">
          <vs-tab label="Statement">
            <div class="tab-text" v-if="isQueryForBankStatementDone">
              <vs-button @click="autoReconcile" class="mb-5 mt-3"
                >Reconcile</vs-button
              >
              <vs-prompt
                color="warning"
                title="Auto Reconcile"
                @accept="acceptReconcile(autoReconcileData)"
                @close="closeAutoReconcilePrompt"
                :active.sync="showAutoreconcilePrompt"
              >
                <div class="flex flex-col gap-4">
                  <p class="font-bold">
                    Some bank staments has the same reconcile suggestion. if you
                    continue, the system will reconcile the latest bank
                    statement
                  </p>
                  <p class="font-bold">Are you sure want to continue?</p>
                </div>
              </vs-prompt>
              <data-table
                :responseData="responseData"
                :propsParams="params"
                :header="header"
                @reloadDataFromChild="reloadData"
              >
                <template slot="thead">
                  <th width="7%">Action</th>
                </template>
                <template slot="tbody">
                  <vs-tr
                    :key="indextr"
                    v-for="(tr, indextr) in responseData.bankStatementLines"
                    :class="tr.class"
                  >
                    <vs-td class="whitespace-no-wrap">
                      <template>
                        <div class="mt-4 flex justify-between">
                          <vx-tooltip text="Show" class="mr-4">
                            <vs-button
                              type="line"
                              icon-pack="feather"
                              icon="icon-eye"
                              v-on:click.stop="handleShow(tr)"
                            />
                          </vx-tooltip>
                          <vx-tooltip
                            text="Add Group"
                            class="mr-4"
                            v-if="!tr.Child || tr.Child.length < 1"
                          >
                            <vs-button
                              type="line"
                              icon-pack="feather"
                              icon="icon-copy"
                              v-on:click.stop="handleGroup(tr)"
                            />
                          </vx-tooltip>
                          <vx-tooltip text="See Group" class="mr-4" v-else>
                            <vs-button
                              type="line"
                              icon-pack="feather"
                              icon="icon-list"
                              v-on:click.stop="handleSeeGroup(tr)"
                            />
                          </vx-tooltip>
                        </div>
                      </template>
                    </vs-td>
                    <vs-td>
                      {{ tr.TypeTransaction }}
                    </vs-td>
                    <vs-td>
                      {{ tr.BranchCode }} <br />
                      {{ getOUName(tr.TerritoryID) }}
                    </vs-td>
                    <vs-td>
                      <!-- {{ tr.TerritoryID}} -->
                      {{ getBranchCode(tr.TerritoryID) }}
                      <br />
                      {{ getBracnhName(tr.TerritoryID) }}
                      <br />
                    </vs-td>
                    <vs-td>
                      {{ tr.NameVa }}
                    </vs-td>
                    <vs-td>
                      <div v-if="tr.TransactionValue >= 0">
                        Value : {{ formatPrice(tr.TransactionValue) }}<br />
                      </div>
                      <div v-else>
                        <vs-alert
                          color="warning"
                          class="mt-3"
                          icon-pack="feather"
                          close-icon="icon-x"
                        >
                          Value : {{ formatPrice(tr.TransactionValue) }}
                        </vs-alert>
                      </div>
                      Statement Code :
                      {{ tr.BankStatement.Code }}<br />
                      Reference Code :
                      {{ tr.ReferenceCode ? tr.ReferenceCode : "-" }}<br />
                      No Giro : {{ tr.NoGiro ? tr.NoGiro : "-" }}<br />
                      Remark : {{ tr.Note }}<br />
                      Txn Date : {{ dateFormat(tr.TxnDate) }}<br />
                      <template v-if="tr.CashBankID != 0">
                        Cash Bank Code : {{ tr.CashBankCode }}
                      </template>
                      <template v-if="tr.PaymentID != 0">
                        Payment Code : {{ tr.PaymentCode }}
                      </template>

                      <!-- Reference Code : {{tr.ReferenceCode}}<br>
                          Cash Bank Code : {{tr.CashBankCode}} -->
                    </vs-td>
                    <vs-td>
                      <reconcile_recommendation
                        :reconcileRecommendation="
                          reconcileRecomendations[indextr].data
                            ? reconcileRecomendations[indextr].data
                            : []
                        "
                      />
                    </vs-td>
                    <vs-td>
                      <vs-checkbox
                        v-if="isShowCheckbox(tr.ID)"
                        :checked="checked(tr)"
                        @click="addChecked(tr)"
                      />
                    </vs-td>
                  </vs-tr>
                </template>
              </data-table>
            </div>
          </vs-tab>

          <vs-tab label="Reconciled">
            <div class="tab-text">
              <data-table
                :responseData="responseDataReconciled"
                :propsParams="params"
                :header="headerReconcile"
                @reloadDataFromChild="reloadDataReconciled"
              >
                <template slot="thead">
                  <th width="7%">Action</th>
                </template>
                <template slot="tbody">
                  <vs-tr
                    :key="indextr"
                    v-for="(
                      tr, indextr
                    ) in responseDataReconciled.bankStatementLines"
                    :class="tr.class"
                  >
                    <vs-td class="whitespace-no-wrap">
                      <template>
                        <div class="mt-4 flex justify-between">
                          <vx-tooltip
                            text="Show"
                            class="mr-4"
                            v-if="
                              tr.CashBankID == 0 &&
                              tr.PaymentID == 0 &&
                              tr.bankStatementLineID == 0
                            "
                          >
                            <vs-button
                              type="line"
                              icon-pack="feather"
                              icon="icon-eye"
                              v-on:click.stop="handleShow(tr)"
                            />
                          </vx-tooltip>
                          <template v-else>
                            <div>
                              <!-- <vs-button class="ml-auto mt-2" v-on:click.stop="handleUnreconciled(tr)">Unreconciled</vs-button> -->
                              <vs-td class="whitespace-no-wrap">
                                <template>
                                  <div class="mt-4 flex justify-between">
                                    <vx-tooltip text="See Group" class="mr-4">
                                      <vs-button
                                        type="line"
                                        icon-pack="feather"
                                        icon="icon-eye"
                                        v-on:click.stop="handleListGroup(tr)"
                                      />
                                    </vx-tooltip>

                                    <vx-tooltip
                                      text="See Reconcile"
                                      class="mr-4"
                                    >
                                      <vs-button
                                        type="line"
                                        icon-pack="feather"
                                        icon="icon-list"
                                        v-on:click.stop="handleSeeReconcile(tr)"
                                      />
                                    </vx-tooltip>

                                    <vx-tooltip
                                      text="Unreconciled"
                                      class="mr-4"
                                    >
                                      <vs-button
                                        type="line"
                                        icon-pack="feather"
                                        icon="icon-delete"
                                        color="danger"
                                        v-on:click.stop="handleUnreconciled(tr)"
                                      />
                                    </vx-tooltip>
                                  </div>
                                </template>
                              </vs-td>
                            </div>
                          </template>
                        </div>
                      </template>
                    </vs-td>
                    <vs-td>
                      {{ tr.TypeTransaction }}
                    </vs-td>
                    <vs-td>
                      {{ tr.BranchCode }} <br />
                      {{ getOUName(tr.TerritoryID) }}
                    </vs-td>
                    <vs-td>
                      <!-- {{ tr.TerritoryID}} -->
                      {{ getBranchCode(tr.TerritoryID) }}
                      <br />
                      {{ getBracnhName(tr.TerritoryID) }}
                      <br />
                      <!-- {{ tr.TerritoryCode }} -->
                      <!-- {{option.territory.filter(function(territory)  {
                  return territory.ID == tr.TerritoryID;
                })[0].code}} -->
                    </vs-td>
                    <vs-td>
                      {{ tr.NameVa }}
                    </vs-td>
                    <vs-td>
                      Value : {{ formatPrice(tr.TransactionValue) }}<br />
                      Statement Code :
                      {{ tr.BankStatement.Code }}<br />
                      Reference Code :
                      {{ tr.ReferenceCode ? tr.ReferenceCode : "-" }}<br />
                      No Giro : {{ tr.NoGiro ? tr.NoGiro : "-" }}<br />
                      Remark : {{ tr.Note }}<br />
                      Txn Date : {{ dateFormat(tr.TxnDate) }}<br />
                      <template v-if="tr.CashBankID != 0">
                        Cash Bank Code : {{ tr.CashBankCode }}
                      </template>
                      <template v-if="tr.PaymentID != 0">
                        Payment Code : {{ tr.PaymentCode }}
                      </template>
                    </vs-td>
                  </vs-tr>
                </template>
              </data-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <!-- SEE GROUP & UNGROUP TAB STATEMENT PROMPT -->
      <div>
        <vs-prompt
          title="List Group"
          color="primary"
          @accept="handleUnGroup(ID)"
          accept-text="Ungroup"
          @cancel="closeDetail"
          @close="closeDetail"
          :buttons-hidden="false"
          :active.sync="seeGroupPrompt"
        >
          <div v-if="this.child != null">
            <div class="con-exemple-prompt">
              <div class="prompt-container" ref="promptContainer">
                <div :key="indextr" v-for="(tr, indextr) in this.child">
                  <br />
                  Branch Code : {{ tr.BranchCode }} <br />
                  <!-- Statement Code :{{ tr.BankStatement.Code }}<br /> -->
                  Payment Value : {{ priceFormat(tr.TransactionValue) }}<br />
                  Name VA : {{ tr.NameVa }} <br />
                  Reference Code : {{ tr.ReferenceCode ? tr.ReferenceCode : "-"
                  }}<br />
                  No Giro : {{ tr.NoGiro ? tr.NoGiro : "-" }}<br />
                  Remark : {{ tr.Note }}<br />
                  Txn Date : {{ dateFormat(tr.TxnDate) }}<br />
                  <br />
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </vs-prompt>
      </div>
      <!-- SEE GROUP TAB RECONCILED PROMPT -->
      <div>
        <vs-popup
          title="List Group"
          color="primary"
          @close="closeDetail"
          :buttons-hidden="false"
          :active.sync="listGroupPrompt"
        >
          <div v-if="this.child != null">
            <div class="con-exemple-prompt">
              <div class="prompt-container" ref="promptContainer">
                <div :key="indextr" v-for="(tr, indextr) in this.child">
                  <br />
                  Branch Code : {{ tr.BranchCode }} <br />
                  <!-- Statement Code :{{ tr.BankStatement.Code }}<br /> -->
                  Payment Value : {{ priceFormat(tr.TransactionValue) }}<br />
                  Name VA : {{ tr.NameVa }} <br />
                  Reference Code : {{ tr.ReferenceCode ? tr.ReferenceCode : "-"
                  }}<br />
                  No Giro : {{ tr.NoGiro ? tr.NoGiro : "-" }}<br />
                  Remark : {{ tr.Note }}<br />
                  Txn Date : {{ dateFormat(tr.TxnDate) }}<br />
                  <br />
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div v-else>There is no group</div>
        </vs-popup>
      </div>

      <!-- SEE RECONCILE TAB RECONCILED PROMPT -->
      <div>
        <vs-popup
          title="Detail Reconcile"
          color="primary"
          @close="closeDetail"
          :buttons-hidden="false"
          :active.sync="seeReconcilePrompt"
        >
          <div v-if="this.reconcile != null">
            <div class="con-exemple-prompt">
              <div class="popup-container" ref="popupContainer">
                <div :key="indextr" v-for="(tr, indextr) in this.reconcile">
                  <!-- {{tr}} -->

                  <template v-if="tr.type == 'Payment'">
                    Type : {{ tr.type }} <br />
                    Payment Method : {{ tr.payment_method }} <br />
                    Payment Code : {{ tr.code }} <br />
                    Reference Code : {{ tr.reference_code }}<br />
                    Payment Value : {{ priceFormat(tr.payment_value) }}<br />
                    Customer Name : {{ tr.customer_name }} <br />
                    Customer Code : {{ tr.customer_code }}<br />
                    Bank Name : {{ tr.bank_name }} <br />
                    Bank Account Number : {{ tr.bank_account_number }} <br />
                  </template>
                  <template v-if="tr.type == 'Cash Bank'">
                    <!-- Cash Bank Code :  {{ tr.cash_bank_code }} <br> -->
                    Type : {{ tr.type }} <br />
                    Method : {{ methods[tr.method] }}<br />
                    Cash Bank Code : {{ tr.code }} <br />
                    Reference Code : {{ tr.reference_code }} <br />
                    Deposit Number : {{ tr.deposit_number }} <br />
                    Deposit Value : {{ priceFormat(tr.deposit_value) }} <br />
                    Bank : {{ tr.bank }} <br />
                    Account Name : {{ tr.account_name }} <br />
                    Account Number : {{ tr.account_number }} <br />
                  </template>
                  <template v-if="tr.type == 'Bank Statement'">
                    <!-- Bank Statement Code :  {{ tr.bank_statement_code }} <br> -->
                    Type : {{ tr.type }} <br />
                    Branch Code : {{ tr.branch_code }} <br />
                    Bank Statement Code : {{ tr.statement_code }} <br />
                    Reference Code : {{ reference_code }} <br />
                    Payment Value : {{ priceFormat(tr.payment_value) }} <br />
                    Name VA : {{ tr.name_va }} <br />
                    Value : {{ tr.value }} <br />
                    No Giro : {{ tr.no_giro }} <br />
                    Remark : {{ tr.remark }} <br />
                    Txn Date : {{ tr.txn_date }} <br />
                  </template>
                  <template v-if="tr.type == 'Supplier Payment'">
                    <!-- Supplier Payment Code :  {{ tr.supplier_payment_code }} <br> -->
                    Type : {{ tr.type }} <br />
                    Bank Account Name : {{ tr.bank_account_name }} <br />
                    Bank Account Number : {{ tr.bank_account_number }} <br />
                    Bank Name : {{ tr.bank_name }} <br />
                    Supplier Payment Code : {{ tr.code }} <br />
                    Reference Code : {{ tr.reference_code }} <br />
                    Giro Number : {{ tr.giro_number }} <br />
                    Paymet Value : {{ priceFormat(tr.payment_value) }} <br />
                    Supplier Code : {{ tr.supplier_code }} <br />
                    Supplier Name : {{ tr.supplier_name }} <br />
                  </template>
                  <br />
                  <hr />
                  <br />
                  <template v-if="additionalCoa && additionalCoa.length > 0">
                    <div
                      class="mb-6"
                      v-for="(coa, index) in additionalCoa"
                      :key="index"
                    >
                      COA Name : {{ coa.coa }}<br />
                      Cost Center : {{ coa.cost_center }}<br />
                      Territory : {{ coa.territory }} <br />
                      Additional Value : {{ priceFormat(coa.additional_value) }}
                    </div>
                  </template>
                </div>

                <!-- </div> -->
              </div>
            </div>
          </div>
        </vs-popup>
      </div>

      <!-- UNRECONCILED TAB RECONCILED PROMPT -->
      <div>
        <vs-prompt
          title="Confirmation"
          color="primary"
          @accept="doSubmit"
          @cancel="closeDetail"
          @close="closeDetail"
          :buttons-hidden="false"
          :active.sync="rejectPrompt"
        >
          <div class="con-exemple-prompt">
            Reconciliation
            <br />
            Are you sure to unreconciled ?
            <br />
            <br />
          </div>
        </vs-prompt>
      </div>

      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'vx-col md:w-3/4 w-full  mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>

            <template v-if="!formGroup">
              <detail-div
                @close="handleClose"
                :selected="selectedData"
                :operatingUnitText="operatingUnitText"
              />
            </template>
            <template v-else>
              <!-- <template v-if="!seeGroup"> -->
              <group-div
                @close="handleClose"
                :selected="selectedData"
                :operatingUnitText="operatingUnitText"
              />
            </template>
          </vs-row>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import detail from "./detail.vue";
import group from "./group.vue";
import reconcile_recommendation from "./reconcile_recommendation.vue";
//import seegroup from "./seegroup.vue";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
    "group-div": group,
    reconcile_recommendation: reconcile_recommendation,
    DateRangePicker,
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
        status: 0,
      },
      date: {
        startDate: null,
        endDate: null,
      },
      header: [
        {
          text: "Type Transaction",
          value: "type_transaction",
          // width: '5%'
        },
        {
          text: "Branch Code",
          value: "branch_code",
        },
        {
          text: "Territory Code",
          value: "territory_code",
        },
        {
          text: "Name VA",
          value: "name_va",
        },
        {
          text: "Informasi",
          sortable: false,
        },
        {
          text: "Suggest",
          sortable: false,
        },
        {
          text: "",
        },
      ],
      headerReconcile: [
        {
          text: "Type Transaction",
          value: "type_transaction",
          // width: '5%'
        },
        {
          text: "Branch Code",
          value: "branch_code",
        },
        {
          text: "Territory Code",
          value: "territory_code",
        },
        {
          text: "Name VA",
          value: "name_va",
        },
        {
          text: "Informasi",
          sortable: false,
        },
      ],
      optionOU: [],
      formGroup: false,
      //seeGroup:false,
      operatingUnit: [],
      territory: [],
      operatingUnitText: "",
      methods: ["", "Cash", "Giro", "Cheque", "Transfer"],
      responseData: {},
      responseDataReconciled: {},
      detailShow: "vx-col md:w-1/4 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      bankStatement: {},
      operatingUnitBank: {},
      optionTerritory: [],
      optionOperatingUnitBank: [],
      bank: {},
      optionBank: [],
      OUTerritory: [],
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      seeGroupPrompt: false,
      seeReconcilePrompt: false,
      listGroupPrompt: false,
      selectedData: {},
      bankStatementID: null,
      child: null,
      ID: null,
      reconcile: null,
      additionalCoa: [],
      reconcileRecomendations: [],
      checkedID: [],
      checkedData: [],
      renderContent: false,
      showAutoreconcilePrompt: false,
      autoReconcileData: [],
      recommendationFilter: null,
      recommendationFilterOptions: [
        { id: 0, name: "All", value: "All" },
        { id: 1, name: "With Recommendation" },
        { id: 2, name: "Without Recommendation" },
      ],
      isQueryForBankStatementDone: false,
      currentTab: 0,
    };
  },
  computed: {
    // operatingUnit(){
    //   console.log(this.operatingUnit,"ou")
    // }
  },
  watch: {
    "date.startDate"() {
      this.params.page = 1;
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
    },
    "date.endDate"() {
      this.params.page = 1;
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
    },
    operatingUnitBank() {
      this.params.page = 1;
      console.log(this.operatingUnit, "ou");
      // this.getBankForm(this.operatingUnit)
      if (this.currentTab == 0) {
        this.reloadData(this.params);
      } else {
        this.reloadDataReconciled(this.params);
      }
      // this.reloadData(this.params);
      // this.reloadDataReconciled(this.params);
      this.renderContent = true;
      // this.handleClose()
    },
    recommendationFilter() {
      this.reloadData(this.params);
    },
  },
  async mounted() {
    await this.getBankForm();
    await this.getOptionOU();
    //tambahan
    console.log(this.selected);
    console.log("mounted");

    // if (this.selected.ReferenceCode != "") {
    //   this.getSuggestion();
    // }
    this.recommendationFilter = { id: 0, name: "All" };
    if (this.selected.TransactionValue < 0) {
      this.type = "bank-statement";
      this.reloadData(this.params);
    } else if (this.selected.TransactionValue > 0) {
      this.type = "cash-deposit";
      this.reloadData(this.params);
    }
  },
  methods: {
    customLabel(option) {
      // Concatenate relevant fields for the search functionality
      return `${option.BankName} - ${option.AccountName} - ${option.AccountNumber}`;
    },
    handleSeeGroup(tr) {
      this.child = tr.Child;
      this.ID = tr.ID;
      // const params = {
      //   "id"      : tr.ID,
      //   "child"   : tr.Child,
      // }
      // console.log("global ch", this.child);
      //console.log("Ini tr", tr);
      // console.log("Ini param", params);
      this.reloadData(this.params);
      this.formGroup = false;
      this.detail = false;
      this.seeGroupPrompt = true;
      this.listGroupPrompt = false;
    },

    handleListGroup(tr) {
      const params = {
        id: tr.ID,
        child: tr.Child,
      };
      this.child = tr.Child;
      this.ID = tr.ID;
      console.log("global ch", this.child);
      this.reloadData(this.params);

      console.log("Ini tr", tr);
      console.log("Ini param", params);

      this.formGroup = false;
      this.detail = false;
      this.seeGroupPrompt = false;
      this.listGroupPrompt = true;
    },

    handleSeeReconcile(tr) {
      const params = {
        id: tr.ID,
        reconcile: tr.Reconcile,
      };
      // this.reconcile = tr.Reconcile;
      this.ID = tr.ID;
      console.log("global ch", this.reconcile);
      // this.reloadData(this.params);
      const reconcile = this.dataTableSeeReconcile(tr.ID);
      reconcile.then((r) => {
        this.reconcile = r.data.detail;
        this.additionalCoa = r.data.additional_coa;
        console.log("coa", this.additionalCoa);
      });

      console.log("Ini tr", tr);
      console.log("Ini param", params);

      this.formGroup = false;
      this.detail = false;
      this.seeGroupPrompt = false;
      this.listGroupPrompt = false;
      this.seeReconcilePrompt = true;
    },

    handleUnGroup(ID) {
      this.$vs.loading();
      this.$http
        .delete("/api/v1/bank-statement/group-manual/" + ID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose(true);

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    async getBankForm() {
      try {
        this.isQueryForBankStatementDone = false;
        this.$vs.loading();
        const r = await this.$http.get("/api/v1/cash-bank/form-bank/0");
        // await this.getOptionOU();
        this.optionOperatingUnitBank = r.data.operatingUnitBank;
        this.operatingUnitBank = r.data.operatingUnitBank[0];
        this.isQueryForBankStatementDone = true;
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },
    findBankStatement(id) {
      this.$http
        .get("/api/v1/bank-statement/data-table/" + id)
        .then((r) => {
          if (r.code == 200) {
            this.bankStatement = r.data.bankStatement;
            console.log(r.data.bankStatement);
          } else {
            this.$vs.notify({
              color: "danger",
              title: r.message,
              text: "error catch",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleBack();
          }
        })
        .catch((e) => {
          this.$vs.notify({
            color: "danger",
            title: "error catch",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.handleBack();
        });
    },
    handleBack() {
      this.$router.push("/finance/bank-statement");
    },
    selectedOperatingUnit() {
      console.log("this.operatingUnitText", this.operatingUnitText);
      if (this.operatingUnit.length == 0) {
        // this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
        // this.territory = { ID: 0, Code: "All", Name: "All" };
        this.operatingUnitText = "";
        this.setOption();
      } else {
        console.log(this.operatingUnit[this.operatingUnit.length - 1].ID);
        console.log(this.operatingUnit);
        if (this.operatingUnit[this.operatingUnit.length - 1].ID == 0) {
          // this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
          // this.territory = { ID: 0, Code: "All", Name: "All" }];
          this.operatingUnitText = "";
          this.setOption();
        } else {
          const operatingUnit = this.operatingUnit.filter((v) => {
            return v.ID != 0;
          });
          if (this.operatingUnit.length > operatingUnit.length) {
            this.operatingUnit = operatingUnit;
          }
          console.log(operatingUnit);
          const ou = [];
          this.operatingUnit.map((v) => {
            //disini
            ou.push(v.ID);
          });
          this.setOption(false, ou);
          // this.operatingUnitText = ou.join(",");
        }
      }
      // this.params.page = 1;
      // this.getBankForm(this.operatingUnit)
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
    },
    selectedTerritory() {
      if (this.territory.length == 0) {
        this.territory = [{ ID: 0, Code: "All", Name: "All" }];
        // this.operatingUnitText = "";
      } else {
        if (this.territory[this.territory.length - 1].ID == 0) {
          this.territory = [{ ID: 0, Code: "All", Name: "All" }];
          // this.operatingUnitText = "";
        } else {
          const territory = this.territory.filter((v) => {
            return v.ID != 0;
          });
          if (this.territory.length > territory.length) {
            this.territory = territory;
          }
        }
      }
      this.setOperatingUnitText();
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
    },
    setOperatingUnitText() {
      let territory = this.territory.filter((v) => {
        return v.ID != 0;
      });

      // let territory = []
      let ou = [];
      if (territory.length > 0) {
        territory.map((v) => {
          //disini
          ou.push(v.ID);
        });
      } else {
        territory = this.optionTerritory.filter((v) => {
          return v.ID != 0;
        });
        territory.map((v) => {
          //disini
          ou.push(v.ID);
        });
        // } else {
        //     this.operatingUnitText = ou.join(",");
      }
      this.operatingUnitText = ou.join(",");
    },
    setOption(ouReplace = true, ouID = []) {
      console.log(ouID);

      if (ouReplace) {
        this.optionOU = [];
        this.optionOU.push({ ID: 0, Code: "All", Name: "All" });
      }
      this.optionTerritory = [];
      this.optionTerritory.push({ ID: 0, Code: "All", Name: "All" });
      let hasID = [];
      this.OUTerritory.map((v) => {
        if (ouReplace) {
          if (!hasID.includes(v.ID)) {
            this.optionOU.push({ ID: v.ID, Code: v.Code, Name: v.Name });
          }
        }
        hasID.push(v.ID);
        console.log(ouID, v.ID, ouID.includes(v.ID));
        if (ouID.length == 0 || ouID.includes(v.ID)) {
          this.optionTerritory.push({
            OuID: v.ID,
            OuName: v.Name,
            ID: v.TerritoryID,
            Code: v.TerritoryCode,
            Name: v.TerritoryName,
          });
        }
      });
      if (ouReplace) {
        this.operatingUnit = [];
        this.operatingUnit.push({ ID: 0, Code: "All", Name: "All" });
      }
      this.territory = [];
      this.territory.push({ ID: 0, Code: "All", Name: "All" });
      this.setOperatingUnitText();
    },
    async getOptionOU() {
      try {
        const resp = await this.$http.get(
          "/api/v1/master/operating-unit-with-territory"
        );
        if (resp.code < 299) {
          this.OUTerritory = resp.data.records;
        } else {
          this.OUTerritory = [];
        }
        this.setOption();
      } catch (e) {
        console.log(e);
      }
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      this.seeGroupPrompt = false;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
      this.seeGroupPrompt = false;
    },

    handleUnreconciled(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 2;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
      this.seeGroupPrompt = false;
    },
    doSubmit() {
      this.$vs.loading();
      const params = {
        id: this.selectedData.ID,
        status: this.approveOrReject,
      };
      console.log("Ini param", params);
      if (this.approveOrReject) {
        this.$nextTick(() => {
          this.confirmation = true;
          this.$vs.loading.close();
        });
        // return
      }

      this.$http
        .post("/api/v1/approval-bank-statement/update", params) //sesuaikan dengan route api
        .then((resp) => {
          console.log(resp);
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },

    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
    },
    downloadExcel() {
      const params = this.params;
      const fileTitle =
        "EXPORT_UNRECONCILE_BANK_STATEMENT" +
        "_" +
        this.operatingUnitBank.BankName +
        "_" +
        this.operatingUnitBank.AccountNumber +
        "_" +
        moment(this.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endDate).format("YYYY-MM-DD");

      this.$http
        .get("/api/v1/bank-statement/by-bank/export/data-table", {
          params: {
            status: 0,
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.sort,
            sort: params.order,
            bank_id: this.operatingUnitBank.BankID,
            account_number: this.operatingUnitBank.AccountNumber,
            territory_text: this.operatingUnitText,
            start_collect_date: this.date.startDate
              ? moment(this.date.startDate).format("YYYY-MM-DD")
              : null,
            end_collect_date: this.date.endDate
              ? moment(this.date.endDate).format("YYYY-MM-DD")
              : null,
            // operating_unit_id: this.operatingUnit ? this.operatingUnit.ID : 0,
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute("download", fileTitle + ".xlsx");

            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    dataTableSeeReconcile(ID) {
      console.log("ID");
      // console.log(this.operatingUnitBank.AccountNumber, 'this.operatingUnitBank.AccountNumber')
      // console.log(this.operatingUnitBank, "dttb");

      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/bank-statement/by-bank/data-table-reconcile", {
            params: {
              id: ID,
            },
          })
          .then((r) => {
            console.log("Done", ID);
            console.log(r);
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    dataTable(params) {
      console.log(
        this.operatingUnitBank.AccountNumber,
        "this.operatingUnitBank.AccountNumber"
      );
      console.log(this.operatingUnitBank, "dttb");
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/bank-statement/by-bank/data-table", {
            params: {
              status: params.status,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              bank_id: this.operatingUnitBank.BankID,
              account_number: this.operatingUnitBank.AccountNumber,
              territory_text: this.operatingUnitText,
              start_collect_date: this.date.startDate
                ? moment(this.date.startDate).format("YYYY-MM-DD")
                : null,
              end_collect_date: this.date.endDate
                ? moment(this.date.endDate).format("YYYY-MM-DD")
                : null,
              recommendation_filter: this.recommendationFilter.name,
              type_transaction: "DEP",
              // operating_unit_id: this.operatingUnit ? this.operatingUnit.ID : 0,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.selectedData.ID = selected.ID;
      this.formGroup = false;
      this.detail = true;
    },

    handleGroup(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.formGroup = true;
      this.detail = true;
    },
    handleClose(reload = true) {
      if (reload) {
        this.reloadData(this.params);
        this.reloadDataReconciled(this.params);
      }
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    reloadData(params) {
      this.handleClose(false);
      this.params = params;
      this.params.status = 0; //reconcile null (approve)
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.bankStatementLines;
          this.responseData = r.data;
          this.reconcileRecomendations = r.data.reconcileRecomendations;
          this.responseData.length = r.data.bankStatementLines.length;
          // this.checkedAll = false;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },

    reloadDataReconciled(params) {
      this.handleClose(false);
      this.params = params;
      this.params.status = 1; //reconcile not null (reject)
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.bankStatementLines;
          this.responseDataReconciled = r.data;
          this.responseDataReconciled.length = r.data.bankStatementLines.length;
          // this.checkedAll = false;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },

    isShowCheckbox(id) {
      const recommendation = this.reconcileRecomendations.find(
        (rec) => rec.id === id
      );

      if (!recommendation || Object.keys(recommendation).length === 0) {
        return false;
      }

      if (!recommendation.data || recommendation.data.length === 0) {
        return false;
      } else {
        return true;
      }
    },

    addChecked(tr) {
      if (this.checkedID.includes(tr.ID)) {
        this.checkedID.splice(this.checkedID.indexOf(tr.ID), 1);
        const index = this.checkedData.findIndex((data) => data.ID === tr.ID);
        this.checkedData.splice(index, 1);
      } else {
        this.checkedID.push(tr.ID);
        this.checkedData.push(tr);
      }
    },

    checked(tr) {
      return this.checkedID.includes(tr.ID);
    },

    findDuplicates(arr) {
      return arr.filter(
        (item, index) =>
          arr.indexOf(item) !== index && arr.lastIndexOf(item) === index
      );
    },

    autoReconcile() {
      if (this.checkedID.length === 0 || this.checkedData.length === 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select at least one data to perform auto reconcile",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      // this.$vs.loading();

      const data = this.checkedData.map((d) => {
        const recommendation = this.reconcileRecomendations.find(
          (rec) => rec.id === d.ID
        );

        const req = {
          id: d.ID,
          type_name: recommendation.data[0].name,
          code: recommendation.data[0].code,
        };

        if (req.type_name === "Payment") {
          req.payment_id = recommendation.data[0].id;
        } else if (
          req.type_name === "Customer Deposit" ||
          req.type_name === "Cash Deposit"
        ) {
          req.cash_bank_id = recommendation.data[0].id;
        } else if (req.type_name === "Supplier Payment") {
          req.supplier_payment_id = recommendation.data[0].id;
        }
        return req;
      });

      const types = [
        "Payment",
        "Customer Deposit",
        "Cash Deposit",
        "Supplier Payment",
      ];

      let isSameReconcile = false;

      //check for same occurance of reconcile
      let duplicate = [];
      let duplicateType = "";
      for (const type of types) {
        duplicateType = type;
        const dataByType = data.filter((d) => d.type_name === type);
        if (dataByType.length === 0) {
          continue;
        }

        const recon_ids = dataByType.map((d) => {
          if (type === "Payment") {
            return d.payment_id;
          } else if (type === "Customer Deposit" || type === "Cash Deposit") {
            return d.cash_bank_id;
          }
          return d.supplier_payment_id;
        });

        duplicate = this.findDuplicates(recon_ids);
        console.log(duplicate, "duplicate");
        if (duplicate.length > 0) {
          isSameReconcile = true;
          break;
        }
      }

      if (isSameReconcile) {
        for (const reconID of duplicate) {
          const cashBankLinesids = data
            .filter((d) => {
              if (duplicateType === "Payment") {
                return d.payment_id === reconID;
              } else if (
                duplicateType === "Customer Deposit" ||
                duplicateType === "Cash Deposit"
              ) {
                return d.cash_bank_id === reconID;
              }
              return d.supplier_payment_id === reconID;
            })
            .map((d) => d.id);
          const minID = Math.min(...cashBankLinesids);
          const index = data.findIndex((d) => d.id === minID);
          data.splice(index, 1);
          this.showAutoreconcilePrompt = true;
          this.autoReconcileData = data;
          return;
        }
      }
      this.acceptReconcile(data);
    },
    acceptReconcile(data) {
      this.$vs.loading();
      const reconcile_lines = {
        reconcile_lines: data,
      };
      this.$http
        .post("/api/v1/bank-statement/auto-reconcile", reconcile_lines)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.showAutoreconcilePrompt = false;
            this.checkedData = [];
            this.checkedID = [];
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
              time: resp.message.includes("does not have match statement date")
                ? 6000
                : 3000,
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    closeAutoReconcilePrompt() {
      this.showAutoreconcilePrompt = false;
      this.autoReconcileData = [];
    },
    getOUName(id) {
      const ou = this.optionTerritory.filter((v) => {
        return v.ID == id;
      });

      if (!ou || ou.length === 0) {
        return "";
      }

      return ou[0].OuName ? ou[0].OuName : "";
    },

    getBranchCode(id) {
      const ou = this.optionTerritory.filter((v) => {
        return v.ID == id;
      });

      if (!ou || ou.length === 0) {
        return "";
      }

      return ou[0].Code ? ou[0].Code : "";
    },

    getBracnhName(id) {
      const ou = this.optionTerritory.filter((v) => {
        return v.ID == id;
      });

      if (!ou || ou.length === 0) {
        return "";
      }

      return ou[0].Name ? ou[0].Name : "";
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}

.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}

.highlight > td {
  background-color: #d0cdf0;
}

.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}

.vs-dialog {
  max-width: 1000px;
}

.prompt-container {
  height: 400px;
  overflow-y: scroll;
}

.popup-container {
  height: 450px;
}

.prompt-message {
  margin-bottom: 10px;
}
</style>
