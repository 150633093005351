<template>
  <div v-if="type == 'payment'">
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr
          :key="indextr"
          v-for="(tr, indextr) in responseData.payment"
          v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']"
        >
          <vs-td> {{ tr.CustomerCode }} {{ tr.CustomerName }} </vs-td>
          <vs-td>
            Payment Code : {{ tr.Code }}
            <br />
            Reference Code : {{ tr.ReferenceCode }}
            <br />
            Method : {{ tr.PaymentMethod }}
            <br />
            Bank : {{ tr.BankName }} ({{ tr.BankAccountNumber }} -
            {{ tr.BankAccountName }})
            <br />
          </vs-td>
          <vs-td>
            <template v-for="r in tr.InvoiceCode.split(',')">
              <div v-bind:key="r">
                {{ r }}
                <br />
              </div>
            </template>
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.Amount) }}
          </vs-td>
          <vs-td>
            Created Date : {{ dateFormat(tr.CreatedAt) }}
            <br />
            Payment Date : {{ dateFormat(tr.DatePayment) }}
            <br />
            Posting Date : {{ dateFormat(tr.PostingDate) }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>

  <!-- IF Supplier Payment -->
  <div v-else-if="type == 'supplier-deposit'">
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="supplierHeader"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr
          :key="indextr"
          v-for="(tr, indextr) in responseData.otherPayment"
          v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']"
        >
          <vs-td> {{ tr.PartnerCode }} - {{ tr.PartnerName }} </vs-td>
          <vs-td>
            Payment Code : {{ tr.Code }}
            <br />
            Reference Code : {{ tr.ReferenceCode }}
            <br />
            Method : {{ supplierMethods[tr.PaymentMethod] }}
            <br />
            Bank : {{ tr.BankName }} ({{ tr.BankAccountNumber }} -
            {{ tr.BankAccountName }})
            <br />
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.Amount) }}
          </vs-td>
          <vs-td>
            Created Date : {{ dateFormat(tr.CreatedAt) }}
            <br />
            Payment Date : {{ dateFormat(tr.DatePayment) }}
            <br />
            Posting Date : {{ dateFormat(tr.DatePosting) }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>

  <!-- IF CASH DEPOSIT -->
  <div v-else>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="headers"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr
          :key="indextr"
          v-for="(tr, indextr) in responseData.cashBank"
          v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']"
        >
          <!-- <vs-td> {{ tr.PersonCode }} {{ tr.PersonName }} </vs-td> -->
          <vs-td>
            {{ `${generateCodeTittle()} Code` }} : {{ tr.Code }}
            <br />
            Reference Code : {{ tr.ReferenceCode }}
            <br />
            Method : {{ methods[tr.Method] }}
            <br />
            Bank : {{ tr.BankName }} ({{ tr.AccountNumber }} -
            {{ tr.AccountName }})
            <br />
          </vs-td>
          <vs-td> {{ priceFormat(tr.DepositValue) }}</vs-td>
          <!-- <vs-td>
        <template v-for="(r) in tr.InvoiceCode.split(',')">
        <div v-bind:key="r">
            {{ r }}
            <br>
        </div>
        </template>
    </vs-td>
    <vs-td>
        {{ priceFormat(tr.Amount) }}
    </vs-td> -->
          <vs-td>
            Created Date : {{ dateFormat(tr.CreatedAt) }}
            <br />
            Payment Date : {{ dateFormat(tr.DatePayment) }}
            <br />
            Posting Date : {{ dateFormat(tr.DatePosting) }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    DateRangePicker,
    Datepicker,
  },
  props: {
    selected: Object,
    option: Object,
    operatingUnitText: String,
    type: String,
    date: Object,
    postingDate: Object,
    operatingUnitBank: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      //   date: {
      //     startDate: null,
      //     endDate: null,
      //     // startPostingDate: null,
      //     // endPostingDate: null,
      //   },
      //   postingdate: {
      //     startDate: null,
      //     endDate: null,
      //   },

      header: [
        {
          text: "Customer",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Invoice",
          sortable: false,
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],

      headers: [
        // {
        //   text: "Customer",
        //   value: "customer_code",
        //   // width: '5%'
        // },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Deposit Value",
          value: "deposit_value",
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],

      supplierHeader: [
        {
          text: "PartNer",
          value: "partner_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],
      // responseData: {},
      methods: ["", "Cash", "Giro", "Cheque", "Transfer"],
      supplierMethods: ["", "Giro", "Cheque", "Transfer"],
      responseData: {},
      detailShow: "vx-col md:w-1/6 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      optionOU: [],
      operatingUnit: [],
      territory: [],
      // operatingUnitText:'',
      bankStatement: {},
      //   operatingUnitBank: {},
      // BankStatement: {},
      optionTerritory: [],
      optionOperatingUnitBank: [],
      OUTerritory: [],
      bank: {},
      optionBank: [],
      PaymentMethod: {},
      PaymentMethodCash: {},
      PaymentMethodTrf: {},
      typePayment: false,
      suggestion: [],
      // type:'cash-deposit',
      suggestID: [],
      other: [],
      optionOther: [],
      invoices: [],
    };
  },
  computed: {},
  watch: {
    "create.method"() {},
    type() {
      this.reloadData(this.params);
    },
    "selected.ID"() {
      this.$nextTick(() => {
        // if (this.selected.ReferenceCode != "") {
        //   this.getSuggestion()
        // }
        // if (this.selected.TransactionValue < 0) {
        //   this.type = 'bank-statement'
        //   console.log('bank-statement')
        // } else {
        //   this.type = 'cash-deposit'
        //   this.reloadData(this.params)
        // }
        // if (this.selected.type == 'payment'){
        //     this.type = 'payment'
        //     this.reloadData(this.params)
        // } else {
        //     this.type = 'cash-deposit'
        //     this.reloadData(this.params)

        // }

        console.log(this.selected);
      });
    },
    // operatingUnit() {
    //     console.log(this.operatingUnit)
    //     console.log("this.operatingUnitText", this.operatingUnitText);
    //     if (this.operatingUnit.length == 0) {
    //         this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
    //         this.operatingUnitText = "";
    //     } else {
    //         if (this.operatingUnit[this.operatingUnit.length - 1].ID == 0) {
    //             this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
    //             this.operatingUnitText = "";
    //         } else {
    //             const operatingUnit = this.operatingUnit.filter((v) => {
    //                 return v.ID != 0;
    //             });
    //             if (this.operatingUnit.length > operatingUnit.length) {
    //                 this.operatingUnit = operatingUnit;
    //             }
    //             console.log(operatingUnit);
    //             const territory = [];
    //             this.operatingUnit.map((v) => {
    //                 //disini
    //                 territory.push(v.TerritoryID);
    //                 console.log(v, "v selected")
    //             });
    //             this.operatingUnitText = territory.join(",");
    //             console.log("this.operatingUnitText 2 ", this.operatingUnitText);
    //         }
    //     }
    //     this.params.page = 1;
    //     // this.getBankForm(this.operatingUnit)
    //     this.reloadData(this.params);
    //     this.reloadDataReconciled(this.params);
    //     // const checkAll = this.operatingUnit.filter(v => {
    //     //   return v.ID == 0
    //     // })

    //     //   if (this.operatingUnit.length == 0) {
    //     //     this.operatingUnit = {ID:0,Code:'All',Name:'All'}
    //     //    this.operatingUnitText = ''
    //     //   } else {
    //     //     if (this.operatingUnit[this.operatingUnit.length-1].ID == 0) {
    //     //     this.operatingUnit = {ID:0,Code:'All',Name:'All'}
    //     //     this.operatingUnitText = ''
    //     //   } else {
    //     //     const operatingUnit = this.operatingUnit.filter(v => {
    //     //       return v.ID != 0
    //     //     })
    //     //     if (this.operatingUnit.length > operatingUnit.length) {
    //     //       this.operatingUnit =  operatingUnit
    //     //     }
    //     //     console.log(operatingUnit)
    //     //     const territory = []
    //     //     this.operatingUnit.map(v => {
    //     //       territory.push(v.OperatingUnitTerritory.TerritoryID)
    //     //     })
    //     //     this.operatingUnitText = territory.join(",")
    //     //   }
    //     // }
    //     // // this.getBankForm(this.operatingUnit)
    //     // console.log("line 435",this.params);
    //     // this.reloadData(this.params)
    //     // // this.reloadDataReconciled(this.params);
    //     // // this.handleClose()
    // },
    "date.startDate"() {
      console.log("ini start", this.params);
      this.reloadData(this.params);
      // this.reloadDataReconciled(this.params);
    },
    "date.endDate"() {
      console.log(this.params);
      this.reloadData(this.params);
      // this.reloadDataReconciled(this.params);
    },
    "postingdate.startDate"() {
      console.log(this.params);
      this.reloadData(this.params);
      // this.reloadDataReconciled(this.params);
    },
    "postingdate.endDate"() {
      console.log(this.params);
      this.reloadData(this.params);
      // this.reloadDataReconciled(this.params);
    },

    operatingUnitBank() {
      console.log(this.operatingUnit, "ou");
      // this.getBankForm(this.operatingUnit)
      this.reloadData(this.params);
      // this.reloadDataReconciled(this.params);
      // this.handleClose()
    },
  },

  mounted() {},
  methods: {
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
    },
    generateCodeTittle() {
      if (this.type == "cash-deposit") {
        return "Cash Deposit";
      } else if (this.type == "customer-deposit") {
        return "Customer Deposit";
      } else if (this.type == "supplier-deposit") {
        return "Supplier Payment";
      }
    },
    getSuggestion() {
      const params = {
        search: this.selected.ReferenceCode,
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.suggestion = r.data.cashBank;
          this.suggestID = [];
          this.suggestion.map((r) => {
            this.suggestID.push(r.ID);
          });
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },

    dataTable(params) {
      // console.log(this.operatingUnitBank.AccountNumber, 'this.operatingUnitBank.AccountNumber')
      // console.log(this.operatingUnitBank, "dttb");
      return new Promise((resolve, reject) => {
        let path = "";
        let status = "";
        let sort = "id";
        let order = "desc";
        let type = 1;
        let method = "";
        let is_reconcile_bank_statement = true;
        if (this.type == "payment") {
          path = "/payment/data-table";
          status = 2;
          (sort = "id"), (order = "desc");
          type = 1;
          is_reconcile_bank_statement = true;
        } else if (this.type == "cash-deposit") {
          path = "/cash-bank/data-table";
          status = 3;
          (order = "id"), (sort = "desc");
          type = 1;
          is_reconcile_bank_statement = true;
          method = 'cash';
        } else if (this.type == "customer-deposit") {
          path = "/cash-bank/data-table";
          status = 3;
          (order = "id"), (sort = "desc");
          type = 2;
          is_reconcile_bank_statement = false;
        } else if (this.type == "supplier-deposit") {
          path = "/other-payment/for-dn/data-table";
          status = 3;
          (order = "desc"), (sort = "id");
        }

        console.log("date :", this.date);
        console.log("postingdate :", this.postingDate);
        if (Object.keys(this.date).length === 0) {
          this.date = {
            startDate: null,
            endDate: null,
          };
        }

        if (Object.keys(this.postingDate).length === 0) {
          this.postingDate = {
            startDate: null,
            endDate: null,
          };
        }
        // this.$http.get('/api/v1/payment/data-table', {
        //   params: {
        this.$http
          .get("/api/v1" + path, {
            params: {
              type,
              status: status,
              search: params.search,
              // length: 10,
              length: params.length,
              page: params.page,
              order: order,
              sort: sort,
              is_reconcile_bank_statement,
              bank_id: this.operatingUnitBank.BankID,
              account_number: this.operatingUnitBank.AccountNumber,
              territory_text: this.operatingUnitText,
              start_collect_date: this.date.startDate
                ? moment(this.date.startDate).format("YYYY-MM-DD")
                : null,
              end_collect_date: this.date.endDate
                ? moment(this.date.endDate).format("YYYY-MM-DD")
                : null,
              start_collect_posting_date: this.postingDate.startDate
                ? moment(this.postingDate.startDate).format("YYYY-MM-DD")
                : null,
              end_collect_posting_date: this.postingDate.endDate
                ? moment(this.postingDate.endDate).format("YYYY-MM-DD")
                : null,
              method,
              // transaction_value: Math.abs(this.selected.TransactionValue),

              // console.log("ini tr", tr);
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getBankForm() {
      // this.$http.get('/api/v1/cash-bank/form-bank/' + this.operatingUnit.OperatingUnitTerritory.TerritoryID).then((r) => {
      this.$http
        .get("/api/v1/cash-bank/form-bank/0")
        .then((r) => {
          // this.optionTerritory = r.data.territory
          // this.optionTerritory = r.data.territories
          // this.optionBank = r.data.bank
          // console.log(r)
          this.getOptionOU();
          this.optionOperatingUnitBank = r.data.operatingUnitBank;
          this.operatingUnitBank = r.data.operatingUnitBank[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    findBankStatement(id) {
      this.$http
        .get("/api/v1/bank-statement/data-table/" + id)
        .then((r) => {
          if (r.code == 200) {
            this.bankStatement = r.data.bankStatement;
            console.log(r.data.bankStatement);
          } else {
            this.$vs.notify({
              color: "danger",
              title: r.message,
              text: "error catch",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleBack();
          }
        })
        .catch((e) => {
          this.$vs.notify({
            color: "danger",
            title: "error catch",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.handleBack();
        });
    },
    handleBack() {
      this.$router.push("/finance/bank-statement");
    },
    selectedOperatingUnit() {
      console.log("this.operatingUnitText", this.operatingUnitText);
      if (this.operatingUnit.length == 0) {
        // this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
        // this.territory = { ID: 0, Code: "All", Name: "All" };
        this.operatingUnitText = "";
        this.setOption();
      } else {
        console.log(this.operatingUnit[this.operatingUnit.length - 1].ID);
        console.log(this.operatingUnit);
        if (this.operatingUnit[this.operatingUnit.length - 1].ID == 0) {
          // this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
          // this.territory = { ID: 0, Code: "All", Name: "All" }];
          this.operatingUnitText = "";
          this.setOption();
        } else {
          const operatingUnit = this.operatingUnit.filter((v) => {
            return v.ID != 0;
          });
          if (this.operatingUnit.length > operatingUnit.length) {
            this.operatingUnit = operatingUnit;
          }
          console.log(operatingUnit);
          const ou = [];
          this.operatingUnit.map((v) => {
            //disini
            ou.push(v.ID);
          });
          this.setOption(false, ou);
          // this.operatingUnitText = ou.join(",");
        }
      }
      // this.params.page = 1;
      // this.getBankForm(this.operatingUnit)
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
    },
    selectedTerritory() {
      if (this.territory.length == 0) {
        this.territory = [{ ID: 0, Code: "All", Name: "All" }];
        // this.operatingUnitText = "";
      } else {
        if (this.territory[this.territory.length - 1].ID == 0) {
          this.territory = [{ ID: 0, Code: "All", Name: "All" }];
          // this.operatingUnitText = "";
        } else {
          const territory = this.territory.filter((v) => {
            return v.ID != 0;
          });
          if (this.territory.length > territory.length) {
            this.territory = territory;
          }
        }
      }
      this.setOperatingUnitText();
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
    },
    setOperatingUnitText() {
      let territory = this.territory.filter((v) => {
        return v.ID != 0;
      });

      // let territory = []
      let ou = [];
      if (territory.length > 0) {
        territory.map((v) => {
          //disini
          ou.push(v.ID);
        });
      } else {
        territory = this.optionTerritory.filter((v) => {
          return v.ID != 0;
        });
        territory.map((v) => {
          //disini
          ou.push(v.ID);
        });
        // } else {
        //     this.operatingUnitText = ou.join(",");
      }
      this.operatingUnitText = ou.join(",");
    },
    setOption(ouReplace = true, ouID = []) {
      console.log(ouID);

      if (ouReplace) {
        this.optionOU = [];
        this.optionOU.push({ ID: 0, Code: "All", Name: "All" });
      }
      this.optionTerritory = [];
      this.optionTerritory.push({ ID: 0, Code: "All", Name: "All" });
      let hasID = [];
      this.OUTerritory.map((v) => {
        if (ouReplace) {
          if (!hasID.includes(v.ID)) {
            this.optionOU.push({ ID: v.ID, Code: v.Code, Name: v.Name });
          }
        }
        hasID.push(v.ID);
        console.log(ouID, v.ID, ouID.includes(v.ID));
        if (ouID.length == 0 || ouID.includes(v.ID)) {
          this.optionTerritory.push({
            OuID: v.ID,
            OuName: v.Name,
            ID: v.TerritoryID,
            Code: v.TerritoryCode,
            Name: v.TerritoryName,
          });
        }
      });
      if (ouReplace) {
        this.operatingUnit = [];
        this.operatingUnit.push({ ID: 0, Code: "All", Name: "All" });
      }
      this.territory = [];
      this.territory.push({ ID: 0, Code: "All", Name: "All" });
      this.setOperatingUnitText();
    },
    getOptionOU() {
      this.$http
        .get("/api/v1/master/operating-unit-with-territory")
        .then((resp) => {
          // this.$http.get("/api/v1/master/operating-unit").then((resp) => {
          // this.optionOU = [...{ID:0,Code:'All',Name:'All'},resp.data.records];

          this.OUTerritory = resp.data.records;
          this.setOption();
        });
    },

    handleShow(selected) {
      this.$router.push("/finance/bank-statement/" + selected.ID);
      // this.selectedData.class = ""
      // selected.class =  "highlight"
      // this.selectedData = selected
      // this.detail = true
    },
    handleClose() {
      this.reloadData(this.params);
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    showDetail(data) {
      this.invoices = data;
      this.detail = 1;
    },

    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    loadMore() {
      // this.$vs.loading();
      this.optionOther.map((r, i) => {
        if (
          i >= this.other.length &&
          i <= parseInt(this.other.length) + parseInt(10)
        ) {
          if (!this.suggestID.includes(r.ID)) {
            this.other.push(r);
          }
        }
      });
    },

    getOther() {
      const params = {
        search: "",
      };
      console.log(params);
      this.$vs.loading();
      this.other = [];
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.optionOther = r.data.cashBank;
          this.optionOther.map((r, i) => {
            if (i < 2) {
              if (!this.suggestID.includes(r.ID)) {
                this.other.push(r);
              }
            }
          });
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },

    reloadData(params) {
      this.params = {
        ...params,
      };
      if (this.type == "cash-deposit") {
        this.params.method = 1;
      }
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          if (this.type == "cash-deposit") {
            this.optionOther = r.data.cashBank;
            this.responseData.length = r.data.cashBank.length;
          } else if (this.type == "payment") {
            this.optionOther = r.data.payment;
            this.responseData.length = r.data.payment.length;
          } else if (this.type == "supplier-deposit") {
            this.optionOther = r.data.otherPayment;
            this.responseData.length = r.data.length;
          }
          this.responseData = r.data;
          console.log(this.responseData);
          // this.data = r.data.bankStatement;
          // this.responseData = r.data;
          // this.responseData.length = r.data.bankStatement.length;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
